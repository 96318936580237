exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-community-tools-tsx": () => import("./../../../src/pages/communityTools.tsx" /* webpackChunkName: "component---src-pages-community-tools-tsx" */),
  "component---src-pages-community-wallet-tsx": () => import("./../../../src/pages/communityWallet.tsx" /* webpackChunkName: "component---src-pages-community-wallet-tsx" */),
  "component---src-pages-explore-tsx": () => import("./../../../src/pages/explore.tsx" /* webpackChunkName: "component---src-pages-explore-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/FAQ.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-license-tsx": () => import("./../../../src/pages/license.tsx" /* webpackChunkName: "component---src-pages-license-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacyPolicy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-signing-tsx": () => import("./../../../src/pages/signing.tsx" /* webpackChunkName: "component---src-pages-signing-tsx" */),
  "component---src-pages-wormhole-tsx": () => import("./../../../src/pages/wormhole.tsx" /* webpackChunkName: "component---src-pages-wormhole-tsx" */),
  "component---src-templates-spacebud-tsx": () => import("./../../../src/templates/spacebud.tsx" /* webpackChunkName: "component---src-templates-spacebud-tsx" */)
}

